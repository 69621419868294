import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Goalrooms from "./pages/Goalrooms";
import Leaderboard from "./pages/Leaderboard";
import Shop from "./pages/Shop";
import Login from "./pages/LoginPage";
import Register from "./pages/RegisterPage";
import AuthPage from "./pages/Auth";
import ChatRoom from "./pages/chatroom/chatroom";
import ProfileSetup from "./pages/ProfileSetupPage";
import PreLaunch from "./pages/prelaunch/Prelaunch";

const App = () => {
  const isPreLaunch = true; // Toggle this to enable/disable the pre-launch page

  if (isPreLaunch) {
    return <PreLaunch />;
  }

  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // Hide Navbar when scrolling down and scrolled past 100px
        setShowNavbar(false);
      } else if (currentScrollY < lastScrollY) {
        // Show Navbar when scrolling up
        setShowNavbar(true);
      }

      setLastScrollY(currentScrollY);
    };

    // Disable scroll handling for specific routes
    const isChatRoom = location.pathname.includes("/chatroom");
    if (!isChatRoom) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, location.pathname]);

  return (
    <>
      <Navbar showNavbar={showNavbar} />
      <Routes>
        {/* Main Pages */}
        <Route path="/" element={<Goalrooms />} />
        <Route path="/goalrooms" element={<Goalrooms />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/shop" element={<Shop />} />

        {/* Authentication */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/auth" element={<AuthPage />} />

        {/* Chat and Profile */}
        <Route path="/chatroom/:id" element={<ChatRoom />} />
        <Route path="/profile/setup" element={<ProfileSetup />} />
      </Routes>
    </>
  );
};

export default App;
