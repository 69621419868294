import React, { useState } from "react";
import { db } from "../../firebase/firebaseConfig"; // Import Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Import Firestore functions
import GoalroomLogo from "../../components/GoalRoomLogo";
import ChatBubble from "../../assets/pics/chatbubble";
import GamingSvg from "../../assets/pics/controllerforprelaunch";
import ReedemIcon from "../../assets/pics/reedem";
import TikTokIcon from "../../assets/pics/tiktok";
import DiscordIcon from "../../assets/pics/discord";
import InstaIcon from "../../assets/pics/instagram";
import "./PreLaunch.css";

const PreLaunch = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      // Save the email to the Firestore collection
      await addDoc(collection(db, "emails"), { email });
      setSuccessMessage(`Thanks for signing up! We'll notify you at ${email} when we launch.`);
      setEmail("");
      setTimeout(() => setSuccessMessage(""), 5000);
    } catch (error) {
      setErrorMessage("Failed to save your email. Please try again.");
      console.error("Error adding email to Firebase:", error);
      setTimeout(() => setErrorMessage(""), 5000);
    }
  };

  // Inline styles for Navbar
  const navbarStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(24, 24, 24, 0.9)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    zIndex: 50,
  };

  return (
    <div className="prelaunch-page min-h-screen bg-[#181818] text-white flex flex-col items-center justify-between p-6 relative">
      {/* Navbar */}
      <nav style={navbarStyles}>
        <GoalroomLogo size={32} />
      </nav>

      {/* Content Section */}
      <div className="transition-opacity duration-1000 mt-16">
        {/* Hero Section */}
        <header className="text-center mt-20">
          <h1 className="text-3xl md:text-5xl poppins-bold mt-4">
            The{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-yellow-600">
              Ultimate Playground
            </span>{" "}
            for Football Fans
          </h1>
          <div className="breaker-gray mt-6 mb-4"></div>
          <p className="text-lg text-gray-300 mt-4 max-w-2xl mx-auto poppins-light">
            Join the Goal Room community to{" "}
            <span className="text-[#51C242] font-semibold">chat</span>,{" "}
            <span className="text-[#51C242] font-semibold">play</span>, and{" "}
            <span className="text-[#51C242] font-semibold">earn rewards</span>{" "}
            while enjoying live football action like never before!
          </p>

          {/* Breaker */}
          <div className="w-full border-t border-gray-600 mt-10"></div>

          {/* Stay Updated Section */}
          <section className="notify-me-section mt-24 text-left">
            <h3 className="text-2xl poppins-semibold text-white">Stay Updated!</h3>
            <p className="text-gray-300 mt-2 poppins-light">
              Sign up with your email, and we'll notify you about the launch and exclusive updates from Goal Room.
            </p>
            <form
              className="mt-6 flex flex-col md:flex-row items-center gap-4"
              onSubmit={handleEmailSubmit}
            >
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-3 w-full md:w-80 text-gray-800 rounded-lg focus:ring-2 focus:ring-[#51C242] focus:outline-none"
                placeholder="Enter your email"
                required
              />
              <button
                type="submit"
                className="bg-[#51C242] text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-all"
              >
                Notify Me
              </button>
            </form>
            {successMessage && <p className="text-green-500 mt-4 poppins-light">{successMessage}</p>}
            {errorMessage && <p className="text-red-500 mt-4 poppins-light">{errorMessage}</p>}
          </section>

          {/* Breaker */}
          <div className="w-full border-t border-gray-600 mt-16"></div>
        </header>

        {/* Features Section */}
        <section className="goal-room-experience text-center relative mt-20">
          <div className="overlay"></div>
          <h2 className="text-4xl poppins-bold text-white mb-10 relative">
            The Goal Room Experience
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
            <div className="p-6 bg-gray-800 rounded-lg hover:prelaunch-hover-glow active:prelaunch-click-glow transition-all duration-300">
              <div className="flex justify-center mb-4">
                <ChatBubble width={48} height={48} />
              </div>
              <h3 className="text-xl text-[#51C242] poppins-medium">Interactive Chatrooms</h3>
              <p className="text-gray-300 mt-2 poppins-light">
                Join rival fans or your team supporters for live discussions and{" "}
                <span className="text-[#51C242]">excitement</span>.
              </p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg hover:prelaunch-hover-glow active:prelaunch-click-glow transition-all duration-300">
              <div className="flex justify-center mb-4">
                <GamingSvg width={48} height={48} />
              </div>
              <h3 className="text-xl text-[#51C242] poppins-medium">Gamified Fun</h3>
              <p className="text-gray-300 mt-2 poppins-light">
                Predict match outcomes, play{" "}
                <span className="text-[#51C242]">trivia</span>, and earn{" "}
                <span className="text-[#51C242]">GoalPoints</span>.
              </p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg hover:prelaunch-hover-glow active:prelaunch-click-glow transition-all duration-300">
              <div className="flex justify-center mb-4">
                <ReedemIcon width={48} height={48} />
              </div>
              <h3 className="text-xl text-[#51C242] poppins-medium">Exclusive Rewards</h3>
              <p className="text-gray-300 mt-2 poppins-light">
                Redeem <span className="text-[#51C242]">GoalPoints</span> for{" "}
                <span className="text-[#51C242]">real-life merchandise</span> and{" "}
                <span className="text-[#51C242]">gifts</span>.
              </p>
            </div>
          </div>
        </section>

        {/* Contacts Section */}
        <section className="contacts-section text-center mt-16">
          <h2 className="text-3xl poppins-bold text-white mb-6">Connect with Us</h2>
          <div className="flex justify-center gap-8">
            <a href="https://www.tiktok.com/@goalroomapp?_t=ZN-8t7u71d01GY&_r=1" target="_blank" rel="noopener noreferrer">
              <TikTokIcon width={40} height={40} />
            </a>
            <a href="https://discord.gg/xxsTpsTtde" target="_blank" rel="noopener noreferrer">
              <DiscordIcon width={40} height={40} />
            </a>
            <a href="https://www.instagram.com/thegoalroom_?igsh=dDNxbHNndjFqaTNu" target="_blank" rel="noopener noreferrer">
              <InstaIcon width={40} height={40} />
            </a>
          </div>
        </section>

        {/* Footer */}
        <footer className="text-center mt-16 py-6 bg-[#181818] text-gray-500">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Goal Room. All rights reserved.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default PreLaunch;
