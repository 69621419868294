// Import the necessary functions from Firebase SDK
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Import GoogleAuthProvider
import { getStorage } from "firebase/storage"; // Import Firebase Storage

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJe-JtD6lchq3mCFsnx-ClIWhbLW0AO7c",
  authDomain: "goalroom-11dd9.firebaseapp.com",
  projectId: "goalroom-11dd9",
  storageBucket: "goalroom-11dd9.appspot.com",
  messagingSenderId: "468980180646",
  appId: "1:468980180646:web:06e52cb359cdb7c0ba3667",
  measurementId: "G-2LX0PBN80D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Auth
export const auth = getAuth(app);

// Initialize Google Auth Provider
export const googleProvider = new GoogleAuthProvider(); // Export GoogleAuthProvider

// Initialize Firebase Storage
export const storage = getStorage(app);
