import React from "react";

const GoalroomLogo = ({ size = 32 }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: size,
        fontWeight: 600, // Set font-weight to semi-bold
        lineHeight: 1,
        fontFamily: "Montserrat, sans-serif", // Use Montserrat font
      }}
    >
      <span style={{ color: "white" }}>Goal</span>
      <span style={{ color: "#51C242", marginLeft: "0.2em" }}>Room</span>
    </div>
  );
};

export default GoalroomLogo;
